import { ServiceProvider } from "@gms/tsp-api";
import { emptyRequestStatus, IDataState } from "../app/app.models";
import { FeatureFlag, InfoPostDetails, InfoPostNoticeDetails, InfoPostNoticeTypes, NoticeCapacityReleaseIndex, NoticeCapacityReleaseIndexDetails, StaticInformationDetails } from "@gms/reporting-api";
import { RealTimeRollingAvg, BlendingModel } from "@gms/measurement-api";
import { HttpResponse } from "@angular/common/http";

export interface IInfoPostState {
  isLoading?: boolean;
  error?: any;
  notices: IDataState<InfoPostNoticeDetails>;
  noticeTypes: IDataState<Array<InfoPostNoticeTypes>>;
  postings: IDataState<InfoPostDetails>;
  naesbList: IDataState<InfoPostDetails>;
  tspServiceRequester: IDataState<Array<ServiceProvider>>;
  headerInfoPostTSP: ServiceProvider;
  menuitemsComments: IDataState<StaticInformationDetails>;
  selectedTspID?: number;
  noticeCapacityRelease: IDataState<NoticeCapacityReleaseIndexDetails>;
  groupByPostingData: IDataState<Array<GroupByPostingData>>;
  allNoticesData: IDataState<Array<AllNoticesData>>;
  downloadNoticeDocument: { loading: boolean; httpError: Error; noticeID: number };
  receiptPointData: IDataState<RealTimeRollingAvg>,
  rollingAvgData: IDataState<RealTimeRollingAvg>,
  strattonData: IDataState<BlendingModel>,
}

export const initialInfoPostState: IInfoPostState = {
  notices: undefined,
  noticeTypes: null,
  postings: undefined,
  naesbList: undefined,
  tspServiceRequester: null,
  headerInfoPostTSP: null,
  menuitemsComments: null,
  selectedTspID: null,
  noticeCapacityRelease: null,
  groupByPostingData: null,
  allNoticesData: null,
  downloadNoticeDocument: null,
  receiptPointData: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  rollingAvgData: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  strattonData: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  }
}

export interface GroupByPostingData {
  tspId: number;
  data: InfoPostDetails;
}

export interface AllNoticesData {
  tspId: number;
  data: InfoPostNoticeDetails;
}
