import {
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule,
  Pipe,
  PipeTransform,
  Type,
  ɵstringify as stringify,
} from '@angular/core';
// IMPORTANT: This import below must be relative to avoid breaking E2E tests that rely on it
import { formatDate } from '../utils/formatDate.util';

/**
 * @see formatDate from `/shared/utils/formatDate.util`
 *
 */
@Injectable()
@Pipe({ name: 'date', pure: true })
export class DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  /**
   * @param value The date expression: a `Date` object,  a number
   * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
   * @param format The date/time components to include, using predefined options or a
   * custom format string.
   * @param timezone A timezone offset (such as `'+0430'`), or a standard
   * UTC/GMT or continental US timezone abbreviation.
   * When not supplied, uses the end-user's local system timezone.
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
   * @returns A date string in the desired format.
   */
  public transform(
    value: any,
    format = 'mediumDate',
    timezone?: string,
    locale?: string
  ): string | null {
    if (value == null || value === '' || value !== value) return null;

    try {
      return formatDate(value, format, locale || this.locale, timezone);
    } catch (error) {
      throw invalidPipeArgumentError(DatePipe, error.message);
    }
  }
}

function invalidPipeArgumentError(type: Type<any>, value: {}) {
  return Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}

@NgModule({
  exports: [DatePipe],
  declarations: [DatePipe],
})
export class DatePipeModule {}
