import { FormControl, FormGroup } from '@angular/forms';
import { TspHoliday } from '@gms/rateschedulev2-api';
import { addYears } from '@progress/kendo-date-math';
import get from 'lodash/get';
import {
  cleanAOE,
  cleanDateWithMM_DD_YYYY,
  isValidDate,
  roundNumber,
} from 'shared/services/data-cleaner.service';
import { formatDate } from 'shared/utils/formatDate.util';
import { isNullOrUndefined } from 'shared/utils/type.utils';

export enum dateType {
  Y = 'Y',
  M = 'M',
  D = 'D',
}

const dateTypeToStringMapping: { [key in keyof typeof dateType]: string } = {
  Y: 'Year',
  M: 'Month',
  D: 'Day',
};

export interface BusinessDate {
  date?: string;
  dateTime?: Date;
  isHoliday?: boolean;
  isWeekend?: boolean;
}

export interface DatePlaceholderFormat {
  year: string;
  month: string;
  day: string;
}

export namespace sameMonthDates {
  export const endChange = (
    form: FormGroup<{ endDate: FormControl<Date>; beginDate: FormControl<Date> } & any>,
    emitEvent = false
  ): void => {
    try {
      const endDate = get(form, 'value.endDate') as Date;
      const beginDate = get(form, 'value.beginDate') as Date;

      const year = endDate.getFullYear();
      const month = endDate.getMonth();
      const newBeginDate = new Date(year, month);

      if (
        beginDate.getMonth() !== endDate.getMonth() ||
        beginDate.getFullYear() !== endDate.getFullYear()
      ) {
        form.controls.beginDate.setValue(newBeginDate, { emitEvent });
      }

      if (endDate.getMonth() === beginDate.getMonth()) {
        // check that the dates did not overlap, if so, default to same day
        if (endDate < beginDate) {
          form.controls.beginDate.setValue(endDate, { emitEvent });
        }
      }
    } catch (e) { }
  };

  export const beginChange = (
    form: FormGroup<{ endDate: FormControl<Date>; beginDate: FormControl<Date> } & any>,
    emitEvent = false
  ): void => {
    try {
      const endDate = get(form, 'value.endDate') as Date;
      const beginDate = get(form, 'value.beginDate') as Date;

      const year = beginDate.getFullYear();
      const month = beginDate.getMonth();
      const newEndDate = new Date(year, month);
      if (
        endDate.getMonth() !== beginDate.getMonth() ||
        endDate.getFullYear() !== beginDate.getFullYear()
      ) {
        form.controls.endDate.setValue(beginDate, { emitEvent });
      }

      if (beginDate.getMonth() === newEndDate.getMonth()) {
        // check that the dates did not overlap, if so, default to same day
        if (beginDate > newEndDate && beginDate.getDate() > endDate.getDate()) {
          form.controls.endDate.setValue(beginDate, { emitEvent });
        }
      }
    } catch (e) { }
  };
}

export const dateUtils = {
  getTodayPlusDays: (daysToAdd: number): Date => {
    const todayPlusDays = dateUtils.getToday();
    todayPlusDays.setDate(todayPlusDays.getDate() + daysToAdd);
    return todayPlusDays;
  },

  getTodayMinusOne: (): Date => {
    const todayMinusOne = dateUtils.getToday();
    todayMinusOne.setDate(todayMinusOne.getDate() - 1);
    return todayMinusOne;
  },

  getToday: (date?: string) => {
    if (dateUtils.isUserTimeZoneCrossIntlDateLine()) {
      return isNullOrUndefined(date)
        ? dateUtils.getTodayAOE()
        : dateUtils.getAOEDateForDashedString(date);
    }

    const today = isNullOrUndefined(date) ? new Date() : new Date(date);
    return dateUtils.convertToCT(today);
  },

  getCurrentGasDay: () => {
    return dateUtils.getToday().getHours() < 9
      ? dateUtils.getTodayMinusOne()
      : dateUtils.getToday();
  },

  getFirstOfMonth: (): Date => {
    const today = dateUtils.getToday();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  },

  getOneMonthPriorToAccountingMonth: () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(1);
    const oneMonthPrior = today.getMonth() - 1;
    today.setMonth(oneMonthPrior);

    return today;
  },

  stripTimeFromDate(date: string | Date): any {
    if (isNullOrUndefined(date) || date === '') return date;

    switch (typeof date) {
      case 'string':
        // Only accounting for the format MM-DD-YYYYT00:00:00.000Z
        const dateString = date.substring(0, 10);
        return this.convertUTCJsonDateToDateObject(dateString);
      case 'object':
        return this.getDateWithMM_DD_YYYY(date);
      default:
        return date;
    }
  },

  cleanDateAndConvertToUTC(dateStr: string | Date, notDefined: any = ''): any {
    let date;
    if (!isNullOrUndefined(dateStr)) {
      date = new Date(dateStr).toISOString();
    }
    return cleanDateWithMM_DD_YYYY(date, notDefined);
  },

  getSixMonthsPriorToAccountingMonth: () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(1);
    const sixMonthsPrior = today.getMonth() - 7;
    today.setMonth(sixMonthsPrior);

    return today;
  },

  convertJSONDateToDateObject: date => {
    return date ? new Date(date) : '';
  },

  convertSimpleDateToDateObject: (date: string) => {
    if (isNullOrUndefined(date)) {
      return null;
    }
    const RegExpNamedCaptureGroups = new RegExp('([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})');
    const match = RegExpNamedCaptureGroups.exec(date);
    return new Date(parseInt(match[3], 10), parseInt(match[1], 10) - 1, parseInt(match[2], 10));
  },

  convertJSONSimpleDateToDateObject: (date: string) => {
    if (isNullOrUndefined(date)) {
      return null;
    }

    let dateString: string;
    let parts: string[];
    let year: number;
    let month: number;
    let day: number;

    if (date.includes('(')) {
      date = new Date(date).toISOString();
    }

    const slashRegex = new RegExp('\\d+/\\d+/\\d+ ');
    const timeRegex = new RegExp('\\dT\\d');
    if (slashRegex.test(date)) {
      dateString = date.split(' ')[0];
      parts = dateString.split('/');
      year = +parts[2];
      month = +parts[0] - 1; // Note: months are 0-based
      day = +parts[1];
      return new Date(year, month, day);
    }

    if (timeRegex.test(date)) {
      dateString = date.split('T')[0];
    } else {
      dateString = date;
    }

    parts = dateString.split('-');
    year = +parts[0];
    month = +parts[1] - 1; // Note: months are 0-based
    day = +parts[2];
    return new Date(year, month, day);
  },

  convertDateObjectTOJSONSimpleDate: (date: Date): string => {
    if (isNullOrUndefined(date)) return;

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();

    if (day.length === 1) day = `0${day}`;
    if (month.length === 1) month = `0${month}`;

    if (year.length === 4) year = `${year}`;
    if (year.length === 3) year = `0${year}`;
    if (year.length === 2) year = `00${year}`;
    if (year.length === 1) year = `000${year}`;

    return `${year}-${month}-${day}`;
  },

  convertUTCJsonDateToDateObject: date => {
    return new Date(new Date(date).toLocaleString('en-US', { timeZone: 'UTC' }));
  },

  isValidDate: (date: Date) => {
    //This is to check for invalid date parses. It relies on the fact that NAN !== NAN
    return date && date.getDate ? date.getDate() === date.getDate() : false;
  },

  addDaysToDate: (date: Date, numberOfDays: number) => {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + numberOfDays);

    return newDate;
  },

  addMonthsToDate: (date: Date, numberOfMonths: number) => {
    const newDate = new Date(date.getTime());
    newDate.setMonth(newDate.getMonth() + numberOfMonths);

    return newDate;
  },

  addYearsToDate: (date: Date, numberOfYears: number) => {
    return addYears(date, numberOfYears);
  },

  addHoursToDate: (date: Date, numberOfHours: number): Date => {
    if (!date) {
      date = new Date();
    }

    return new Date(date.setHours(date.getHours() + numberOfHours));
  },

  removeDaysFromDate: (date: Date, numberOfDays: number) => {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() - numberOfDays);

    return newDate;
  },

  /**
   *
   * @param d1 date 1
   * @param d2 date 2
   * @returns -1 d1 is missing or is less than d2
   * @returns 1 d2 is missing or d2 is less
   * @returns 0 dates are the same.
   */
  compare: (d1: Date, d2: Date) => {
    if (!d1) {
      return -1;
    } else if (!d2) {
      return 1;
    }

    const time1 = d1.getTime();
    const time2 = d2.getTime();

    return time1 === time2 ? 0 : time1 < time2 ? -1 : 1;
  },

  compareWithoutHours: (d1: Date, d2: Date) => {
    if (!d1) {
      return -1;
    } else if (!d2) {
      return 1;
    }

    const d1WithoutHours = new Date(d1);
    const d2WithoutHours = new Date(d2);
    d1WithoutHours.setHours(0, 0, 0, 0);
    d2WithoutHours.setHours(0, 0, 0, 0);

    return dateUtils.compare(d1WithoutHours, d2WithoutHours);
  },

  getDateWithMM_DD_YYYY(d1: Date): Date {
    return new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  },

  /**
   * takes a date object and turns it into a string looks like yyyy-mm-dd
   */
  getDateAsYYYY_MM_DD(input: Date | string): string {
    if (!input) {
      return null;
    }

    const date = cleanAOE(input);

    return !isNaN(date.getTime()) //check for invalid date
      ? date.toISOString().split('T')[0]
      : '';
  },

  /**
   * takes a date object and turns it into a string looks like dd-mm-yyyy
   */
  getDateAsDD_MM_YYYY(date: Date): string {
    if (date) {
      return formatDate(date, 'dd-MM-yyyy', 'en-US');
    }
  },

  /**
   * converts a date string to a string that looks like MM/DD/YYYY
   * without leading 0 for single digit days and months.
   * ex.: 4/3/2020
   */
  getDateStringAsMMDDYYYY(date: string): string {
    const convertedDate = cleanAOE(date); //this.convertJSONSimpleDateToDateObject(date);

    if (convertedDate) {
      return `${convertedDate.getMonth() +
        1}/${convertedDate.getDate()}/${convertedDate.getFullYear()}`;
    }
  },

  /**
   * converts a date string to a string that looks like MM/DD/YYYY
   * with leading 0 for single digit days and months.
   * ex.: 04/03/2020
   */
  getDateStringAsMMDDYYYY_WithLeadingZeros(date: string): string {
    const convertedDate = this.convertJSONSimpleDateToDateObject(date);

    if (convertedDate) {
      const convertedMonth =
        convertedDate.getMonth() < 9 //month is zero-indexed
          ? `0${convertedDate.getMonth() + 1}`
          : `${convertedDate.getMonth() + 1}`;
      const convertedDay =
        convertedDate.getDate() < 10 ? `0${convertedDate.getDate()}` : `${convertedDate.getDate()}`;
      return `${convertedMonth}/${convertedDay}/${convertedDate.getFullYear()}`;
    }
  },

  /**
   * converts a date string to a time string
   */
  getDateStringAsAMPM(date: string): string {
    const convertedDate = this.convertJSONDateToDateObject(date);
    return this.getDateAsAMPM(convertedDate);
  },

  getDateAsAMPM(date: Date): string {
    if (date) {
      let hours = date.getHours();
      let minutes: string | number = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = `${minutes < 10 ? '0' : ''}${minutes}`;
      return `${hours}:${minutes} ${ampm}`;
    }
  },

  getTimeASAMPM(time: string): string {
    if (!time) return '';
    const tokens = time.split(':');
    let hour = parseInt(tokens[0], 10);
    let period = 'AM';
    if (hour >= 12) period = 'PM';
    if (hour > 12) hour = hour - 12;
    return `${hour}:${tokens[1]}${period}`;
  },

  /**
   * takes a string in the form yyyy-mm-dd and converts it to a date.
   * This function is used to return dates from strings sent over by the server so
   * formatting and manipulation can be done on the front end.
   */
  getDatefromStringYYYY_MM_DD(date: string, delimiter: string): Date | null {
    if (date) {
      const array = date.split(delimiter);
      if (array.length === 3) {
        const year = parseInt(array[0], 10);
        const month = parseInt(array[1], 10);
        const day = parseInt(array[2], 10);
        return new Date(year, month - 1, day);
      } else {
        return null;
      }
    }
  },

  getDateTimeFromString(date: string) {
    if (date) {
      if (
        date.includes('00:00:00+00:00') ||
        (date.length === 10 && date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g))
      ) {
        return this.convertUTCJsonDateToDateObject(date);
      }
      if (date.includes('+00:00')) {
        return new Date(date);
      }
      const hasDate = date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
      if (hasDate === null) {
        return date; // this is an invalid date - JS is dumb, try it yourself, type new Date('Legal-10003') on your JS console
      }
      const dateArray = hasDate[0].split('-');
      let year, month, day, hour, minute;
      if (dateArray.length === 3) {
        year = parseInt(dateArray[0], 10);
        month = parseInt(dateArray[1], 10);
        day = parseInt(dateArray[2], 10);
      }
      let timeArray;
      if (date.includes('T')) {
        timeArray = date.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/g)[0].split(':');
        if (timeArray.length === 3) {
          hour = parseInt(timeArray[0], 10);
          minute = parseInt(timeArray[1], 10);
        }
      }
      return new Date(year, month - 1, day, hour, minute);
    }
  },

  getTimeFromStringHH_MM_SS(time: string): { hours: number; minutes: number; seconds: number } {
    const dateParts = time.split(':');
    return {
      hours: parseInt(dateParts[0], 10),
      minutes: parseInt(dateParts[1], 10),
      seconds: parseInt(dateParts[2], 10),
    };
  },

  getTimeFromDate_HH_MM(date: Date): string {
    const typedValue = date;

    return (
      typedValue
        .getHours()
        .toString()
        .padStart(2, '0') +
      ':' +
      typedValue
        .getMinutes()
        .toString()
        .padStart(2, '0')
    );
  },

  getDateStringAsMM_YYYY(date: string) {
    const dateArr = date.split('-');
    return dateArr[1] + '_' + dateArr[0];
  },

  mapQuantityAndTypeToYMD(quantity: number, type: dateType) {
    return dateTypeToStringMapping[type]
      ? `${dateTypeToStringMapping[type]}${quantity > 0 || quantity < 0 ? 's' : ''}`
      : '';
  },

  /**
   * Converts a date to the number of days since the epoch, without time
   */
  getDateToDayInt(date: Date): number {
    return Math.floor(dateUtils.getDateWithMM_DD_YYYY(date).getTime() / 1000 / 60 / 60 / 24);
  },

  isInThePast(date: Date): Boolean {
    const today = dateUtils.getToday();
    return dateUtils.compareWithoutHours(date, today) === -1;
  },

  isInTheFuture(date: Date): Boolean {
    const today = dateUtils.getToday();
    return dateUtils.compareWithoutHours(date, today) === 1;
  },

  isToday(date: Date): Boolean {
    const today = dateUtils.getToday();
    return dateUtils.compareWithoutHours(date, today) === 0;
  },

  /*
   * returns a number representing the number of months between a beginning date and an end date.
   * Includes both the begin date month and the end date month in the count
   */
  getMonthInterval(beginDate: Date, endDate: Date): number {
    // months are 0 index + 1 so we get the appropriate number
    const beginMonth: number = beginDate.getMonth() + 1;
    const endMonth: number = endDate.getMonth() + 1;

    const beginYear: number = beginDate.getFullYear();
    const endYear: number = endDate.getFullYear();

    const numberOfYears = endYear - beginYear;

    let numberOfMonths = 0;
    if (numberOfYears > 1) {
      const numberOfYearsInclusive = numberOfYears + 1; // add 1 to it so we include the beginning year
      const numberOfMonthsFirstYear = 12 - beginMonth + 1; // include the beginMonth

      // -2 so we don't count the last year and the first year and only count the number of months in them
      numberOfMonths = 12 * (numberOfYearsInclusive - 2) + numberOfMonthsFirstYear + endMonth;
    } else if (numberOfYears === 0) {
      numberOfMonths = endMonth - beginMonth + 1; // + 1 so we include the beginning month in the count
    } else if (numberOfYears === 1) {
      numberOfMonths = endMonth + (12 - beginMonth + 1); // + 1 so we include the beginning month in the count
    } else {
      numberOfMonths = 0; // number of years is less than 0 which is invalid return 0;
    }

    return numberOfMonths;
  },

  /*
   * Converts a date from Central Time to the time zone of the user.
   */
  convertFromCT(date: Date): Date {
    if (isNullOrUndefined(date)) {
      return null;
    }

    const myTime = new Date();
    const CT = new Date(myTime.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const millisecondDiff = myTime.getTime() - CT.getTime();

    return new Date(date.getTime() + millisecondDiff);
  },

  // helper function for time zone offset for CT
  getCstOffset(date: Date) {
    const stdTimezoneOffset = () => {
      const jan = new Date(0, 1);
      const jul = new Date(6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };
    const isDstObserved = (d: Date) => {
      return d.getTimezoneOffset() < stdTimezoneOffset();
    };
    if (isDstObserved(date)) {
      return -5;
    } else {
      return -6;
    }
  },

  /**
   * Converts a date to Central Time from the time zone of the user.
   *
   * @param {Date} date
   * @returns {Date}
   */
  convertToCT(date: Date): Date {
    if (isNullOrUndefined(date)) {
      return null;
    }

    // convert to msec since Jan 1 1970
    const localTime = date.getTime();
    // obtain local UTC offset and convert to msec
    const localOffset = date.getTimezoneOffset() * 60 * 1000;
    // obtain UTC time in msec
    const utcTime = localTime + localOffset;

    // obtain and add destination's UTC time offset
    const cstOffset = this.getCstOffset(date);
    const ctTime = utcTime + 60 * 60 * 1000 * cstOffset;

    // convert msec value to date string
    return new Date(ctTime);
  },

  /**
   * Prepares the date to be sent to the api by changing timezones and converting to an ISO string.
   */
  convertToApiDate(date: Date): any {
    if (isNullOrUndefined(date)) {
      return null;
    }

    const isoString = dateUtils.convertFromCT(date).toISOString();
    // remove ms, since toISOString sometimes adds a few ms
    return isoString.split('.')[0] + 'Z';
  },

  /**
   * Returns the number of days between two dates
   */
  getDaysBetweenDates(beginDate: Date, endDate: Date, includeEndDate?: boolean): number {
    const msDifference = endDate.getTime() - beginDate.getTime();
    //ms per second * seconds per minute * minutes per hour * hours per day
    const msPerDay = 1000 * 60 * 60 * 24;
    return includeEndDate ? msDifference / msPerDay + 1 : msDifference / msPerDay;
  },

  /**
   * Returns the number of years between two dates
   */
  getYearsBetweenDates(beginDate: Date, endDate: Date): number {
    if (isNullOrUndefined(beginDate) || isNullOrUndefined(endDate)) {
      return null;
    }

    const daysBetweenDates: number = this.getDaysBetweenDates(beginDate, endDate);

    return daysBetweenDates / 365;
  },

  /*
   * Gets the last day of the month
   */
  getLastDayOfMonth(date: Date): Date {
    const month = date.getMonth();
    const year = date.getFullYear();
    return new Date(year, month + 1, 0);
  },

  /*
   * Checks if the date given falls on the weekend
   */
  isWeekendDate(date: Date): boolean {
    return date.getDay() === 6 || date.getDay() === 0;
  },

  /*
   *  Returns month name from the given date object and format i.e. short, long
   */
  getMonthName(date: Date, format: 'short' | 'long'): string {
    return date.toLocaleString('en-US', { month: format });
  },

  /*
   *  Returns month name from the given date object
   */
  getMonthNameLong(date: Date): string {
    return date.toLocaleString('en-US', { month: 'long' });
  },

  /*
   *  Returns day name from the given date object
   */
  getDayNameLong(date: Date): string {
    return date.toLocaleString('en-US', { weekday: 'long' });
  },

  /*
   *  Due to Kendo Datepicker treating input time as UTC causing 1 day off
   *  Generate date from the API format of "2020-02-01T04:00:00+00:00" to Date of user local time
   */
  processDateForKendoDatePickerEdit(dateIn: string): Date {
    if (isNullOrUndefined(dateIn)) {
      return null;
    }
    const dateSplit = dateIn.split('-');
    const year = parseInt(dateSplit[0], 10);
    const mon = parseInt(dateSplit[1], 10) - 1;
    const date = parseInt(dateSplit[2], 10);
    return new Date(year, mon, date);
  },

  /*
   * Kendo Timepicker treats times as user local time.
   * This function will convert the displayed time to central time.
   * Use this before you submit or validate a date that has been created from the time picker that must be in central time.
   * For example: If I am in PST, and I choose 9am, the output date will be 9am PST.
   *   This function will return a new date shifted to 7am PST, which is 9am CST.
   * It will be as if the date picker was functioning in central time.
   */
  processTimeFromTimePicker(dateIn: Date): Date {
    if (!isValidDate(dateIn)) {
      return null;
    }
    const centralTimeOffset = this.getTimezoneOffsetFromCst();
    return new Date(dateIn.getTime() + centralTimeOffset * 60 * 60 * 1000);
  },

  /*
   * Kendo Timepicker treats times as user local time.
   * This function will convert a central time date to be local time, so that it will show the central time time in the picker.
   * Use this before you give a central time date to a control to be used in a time picker.
   * For example: If I am in PST, and I load a 9am CST date, the date would appear as 7am in the picker.
   *   This function will return a new date shifted to 9am PST, so the picker will show 9am.
   */
  processTimeForTimePicker(dateIn: Date): Date {
    if (!isValidDate(dateIn)) {
      return null;
    }
    const centralTimeOffset = this.getTimezoneOffsetFromCst();
    return new Date(dateIn.getTime() - centralTimeOffset * 60 * 60 * 1000);
  },

  createBusinessDate(date: Date): BusinessDate {
    return {
      date: this.getDateAsYYYY_MM_DD(date),
      dateTime: date,
      isWeekend: this.isWeekendDate(date),
    };
  },

  getInitialBusinessDateRange(startDate: Date, endDate: Date): BusinessDate[] {
    const dates: BusinessDate[] = [];

    if (startDate > endDate) {
      return [dateUtils.createBusinessDate(startDate), dateUtils.createBusinessDate(endDate)];
    }

    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(dateUtils.createBusinessDate(currentDate));
      currentDate = dateUtils.addDaysToDate(currentDate, 1);
    }

    return dates;
  },

  getHolidayDates(tspHolidays: TspHoliday[]): Set<string> {
    return new Set(tspHolidays.map(holiday => holiday.dateHoliday));
  },

  getNextBusinessDay(
    date: Date,
    tspHolidays?: TspHoliday[],
    startTime?: number,
    nthBusinessDay: number = 1
  ): Date {
    const startDate = new Date(date);
    const endDate = new Date(date);
    endDate.setDate(date.getDate() + (7 + nthBusinessDay)); // Build a list of next 7+ calendar days

    let dateRange = dateUtils.getInitialBusinessDateRange(startDate, endDate);

    if (tspHolidays) {
      const holidayDates = dateUtils.getHolidayDates(tspHolidays);
      dateRange = dateRange.map((dateInfo: BusinessDate) => {
        const updated: BusinessDate = {
          ...dateInfo,
          isHoliday: holidayDates.has(dateInfo.date),
        };
        return updated;
      });
    }

    let nextBusinessDay = dateRange.filter(
      nextBusinessDate => !nextBusinessDate.isHoliday && !nextBusinessDate.isWeekend
    )[nthBusinessDay - 1].dateTime;

    // if business day's time is greater than start time, get the next business day
    if (
      (startTime && date.getDay() === nextBusinessDay.getDay() && date.getHours() > startTime) ||
      (date.getHours() === startTime && date.getMinutes() > 0)
    ) {
      nextBusinessDay = dateRange.filter(
        nextBusinessDate => !nextBusinessDate.isHoliday && !nextBusinessDate.isWeekend
      )[nthBusinessDay].dateTime;
    }

    return dateUtils.getDateWithMM_DD_YYYY(nextBusinessDay);
  },

  getDateWithoutHours(date: Date | string) {
    const dateNoHours = new Date(date);
    dateNoHours.setHours(0, 0, 0, 0);
    return dateNoHours;
  },

  setDateFieldAsYYYY_MM_DD(item: {}, field: string): void {
    if (!item || !field || !item[field] || isNaN(Date.parse(item[field]))) {
      return;
    }
    item = { ...item, [field]: dateUtils.getDateAsYYYY_MM_DD(new Date(item[field])) };
  },

  // For use to convert date values to what is stored in the database
  getDateToNearestSecond(date: Date | string): Date {
    const ms = new Date(date).getTime();
    // using a negative number here in order to divide by 1000 (t * 10 ^ -3) and round off the milliseconds
    return new Date(roundNumber(ms, -3));
  },

  /**
   * @returns - the central timezone offset in hours from UTC
   */
  getCentralOffset(): number {
    const now = new Date();
    const utcNow = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
    const centralNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

    return Math.round((utcNow.getTime() - centralNow.getTime()) / 1000 / 60 / 60);
  },

  /**
   * @returns - the stringified central timezone offset in hours from UTC
   */
  getCstOffsetString(): string {
    return `-0${this.getCentralOffset()}:00`;
  },

  getCtNow() {
    const now = new Date();
    return new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
  },

  /**
   * @returns - the timezone offset difference from central time for the current user
   */
  getTimezoneOffsetFromCst(): number {
    const now = new Date();
    const centralNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

    return Math.round((now.getTime() - centralNow.getTime()) / 1000 / 60 / 60);
  },

  /**
   *
   * @param input - date to process
   * @param format12hour - optional param to include 'AM/PM in result or not - defaults as true
   * @param includeTz - optional param to include `CT` in result or not - defaults as true
   * @returns - a string of the date's time as hour:minute AM/PM CT
   */
  getCstDisplayTimeFromDate(input: string | Date, format12hour = true, includeTz = true): string {
    if (!input || isNaN(new Date(input).getTime())) {
      return null;
    }

    let format = 'hh:mm';

    if (format12hour) {
      format += ' a';
    }

    if (includeTz) {
      format += ' CT';
    }

    return formatDate(input, format, 'en-US', this.getCstOffsetString());
  },

  /**
   *
   * @param input - date to process
   * @param format12hour - optional param to include 'AM/PM in result or not - defaults as true
   * @param includeTz - optional param to include `CT` in result or not - defaults as true
   * @returns - a string of the date's date and time as month/day/year hour:minute AM/PM CT
   */
  getCstDisplayDateTimeFromDate(
    input: string | Date,
    format12hour = true,
    includeTz = true
  ): string {
    if (!input || isNaN(new Date(input).getTime())) {
      return null;
    }

    let format = 'MM/dd/yyyy - hh:mm';

    if (format12hour) {
      format += ' a';
    }

    if (includeTz) {
      format += ' CT';
    }

    return formatDate(input, format, 'en-US', this.getCstOffsetString());
  },

  /**
   * Gets the current CST timestamp for api requests
   * @returns - formatted cst timestamp
   */
  getCurrentCstTimestamp(): any {
    return formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', this.getCstOffsetString());
  },

  /**
   * Returns a date string formatted in Central time
   * @returns - formatted cst date string
   */
  getFormattedCstDateNoHours(date: Date): any {
    const localDate = this.setDateHoursFromCstOffset(date);
    return formatDate(localDate, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', this.getCstOffsetString());
  },

  /**
   * Creates a date with the hours set as the local timezone offset from CST
   * @returns - Date with cst
   */
  setDateHoursFromCstOffset(date: Date): Date {
    const offset = this.getTimezoneOffsetFromCst();
    const dateWithOffset = new Date(date);
    dateWithOffset.setHours(offset, 0, 0, 0);

    return dateWithOffset;
  },

  /**
   *
   * @returns the same date for anywhere on earth.
   * Anywhere on Earth https://en.wikipedia.org/wiki/Anywhere_on_Earth
   */
  getTodayAOE(): Date {
    return this.getAOEDateForDashedString(
      this.convertDateObjectTOJSONSimpleDate(this.convertToCT(new Date()))
    );
  },

  getAOEDateFor(date: Date) {
    return this.getAOEDateForDashedString(this.convertDateObjectTOJSONSimpleDate(date));
  },

  /**
   * @date date string YYYY-mm-dd
   * @returns the same date for anywhere on earth.
   * Anywhere on Earth https://en.wikipedia.org/wiki/Anywhere_on_Earth
   */
  getAOEDateForDashedString(dateString: string): Date {
    const splitDate = dateString.split('-');
    let date = null;

    if (splitDate.length >= 2) {
      const year = splitDate[0];
      const month = splitDate[1];
      let day = '01';
      if (splitDate.length === 3) {
        day = splitDate[2];
      }

      // convert to UTC -9 only for locations > UTC +12 - NZ, Fiji etc..
      if (dateUtils.isUserTimeZoneCrossIntlDateLine()) {
        date = Date.parse(`${year}-${month}-${day}T00:00:00.000-09:00`);
      } else {
        date = Date.parse(`${year}-${month}-${day}T00:00:00.000-12:00`);
      }

      return new Date(date);
    } else {
      console.log(
        'cannot parse ' +
        typeof dateString +
        ': ' +
        dateString +
        'into AOE date. must be in yyyy-mm-dd'
      );
    }
  },

  /**
   *
   * @param dateTimeString - date time string as yyyy-MM-ddTHH:mm:ssZ
   * @returns the same date for anywhere on earth.
   * Anywhere on Earth https://en.wikipedia.org/wiki/Anywhere_on_Earth
   */
  getAOEDateFromDateTimeString(dateTimeString: string): Date {
    if (!dateTimeString) {
      return null;
    }

    const [dateString] = dateTimeString.split('T');

    if (!dateString) {
      return null;
    }

    return this.getAOEDateForDashedString(dateString);
  },

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  },

  /**
   * Date.prototype.getTimezoneOffset()
   * returns the difference, in minutes, between UTC the local time zone.
   * locales ahead of GMT will thus return negative values and vice-versa
   */
  isUserTimeZoneCrossIntlDateLine(): boolean {
    const tzoffset = (new Date().getTimezoneOffset() / 60) * -1; // negate so that +GMT stays +tive
    return tzoffset - 12 >= 0;
  },

  /**
   * Sets a specified cst time on the input date and converts it to the user's time
   * @param date - date to be modified with a new time in local tz
   * @param hour - hour in cst to be converted and set on the date
   */
  setLocalHourFromCstHour(date: Date, hours: number = 0): void {
    hours = this.getTimezoneOffsetFromCst() + hours;
    date.setHours(hours, date.getMinutes(), date.getSeconds());
  },
  
  /**
   * takes a date object and turns it into a string looks like mm/dd/yyyy
   */
  getDateAsDDMMYYYY(date: Date): string {
    if (date) {
      return formatDate(date, 'MM/dd/yyyy', 'en-US');
    }
  },
  getDatePlaceholderFormat(): DatePlaceholderFormat {
    return { year: 'YYYY', month: 'MM', day: 'DD' };
  },

  get getTimePlaceholder(): string {
    return 'HH:MM';
  },

  get getTimeFormat(): string {
    return 'HH:mm';
  },

  get getDateFormat(): string {
    return 'MM/dd/yyyy';
  }



};
