import { IDataState, emptyRequestStatus } from 'app/store/app/app.models';
import { NoticeFilterModel } from 'app/store/notices/notice-response-model';
import { Notice, NoticeDetails, Noticeslist } from '@gms/reporting-api';

export interface NoticeState {
  notices: IDataState<NoticeDetails>;
  isLoading?: boolean;
  error?: any;
  filterData: NoticeFilterModel;
  lookupData: IDataState<Notice>;
  deleteNotice: IDataState<boolean>;
  createdNewRecord: IDataState<any>;
  noticeDetails: IDataState<Noticeslist>;
}

export const initialNoticeFilter: NoticeFilterModel = {
  filterData: {},
  istriggerSearchApi: true
}

export const initialNoticeState: NoticeState = {
    notices: {
        data: {},
        requestStatus: { ...emptyRequestStatus },
    },
    filterData: initialNoticeFilter,
    lookupData: {
        data: {},
        requestStatus:  { ...emptyRequestStatus },
    },
    deleteNotice: {
        data: false,
        requestStatus: { ...emptyRequestStatus },
      },
    createdNewRecord: {
      data: {},
      requestStatus: { ...emptyRequestStatus },
    },
    noticeDetails: {
      data: { },
      requestStatus: { ...emptyRequestStatus },
    },
}