import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Rum } from 'app/rum.service';
import { IAppState } from 'app/store/app/app.state';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';

export let staticStore: Store<IAppState>;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  extends HasSubscriptions implements OnDestroy {
  title = 'BWP GMS';
  constructor(
    private rum: Rum,
    private _store: Store<IAppState>,
  ) {
    super();
    staticStore = _store;
  }

  ngOnDestroy() {
    this.rum.ngOnDestroy();
    return super.ngOnDestroy();
  }
}