import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { FeatureFlag, InfoPostNoticeDetails, InfoPostNoticeTypes, NoticeCapacityReleaseIndex, NoticeCapacityReleaseIndexDetails, ReportingService, StaticInformationDetails } from "@gms/reporting-api";
import {
    downloadNoticeDocumentAction,
    downloadNoticeDocumentActionError,
    downloadNoticeDocumentActionSuccess,
    fetchAllNotices,
    fetchAllNoticesSuccess,
    fetchGroupByPostingsList,
    fetchGroupByPostingsListSuccess,
    fetchInfoPostTspInfo,
    fetchInfoPostTspInfoError,
    fetchInfoPostTspInfoSuccess, fetchMenuItemComments, fetchMenuItemCommentsError, fetchMenuItemCommentsSuccess, fetchNaesbList, fetchNaesbListError, fetchNaesbListSuccess, fetchNoticeCapacityReleaseIndicesInfo, fetchNoticeCapacityReleaseIndicesInfoError, fetchNoticeCapacityReleaseIndicesInfoSuccess, fetchNoticeInfo, fetchNoticeInfoError, fetchNoticeInfoSuccess,
    fetchNoticeTypeInfo,
    fetchNoticeTypeInfoError,
    fetchNoticeTypeInfoSuccess,
    fetchPostingsList,
    fetchPostingsListError,
    fetchPostingsListSuccess,
    fetchReceiptGasComposition,
    fetchReceiptGasCompositionError,
    fetchReceiptGasCompositionSuccess,
    fetchRollingAvgGasComposition,
    fetchRollingAvgGasCompositionError,
    fetchRollingAvgGasCompositionSuccess,
    fetchStrattonRidgeGasComposition,
    fetchStrattonRidgeGasCompositionError,
    fetchStrattonRidgeGasCompositionSuccess,
} from "./infopost.actions";
import { catchError, concatMap, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ServiceProvider, TspService } from "@gms/tsp-api";
import { LABELS } from "shared/consts/labels.const";
import { BlendingModel, CoastalBendHeaderService, MeasurementsService, RealTimeRollingAvg } from "@gms/measurement-api";
import { HttpResponse } from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})

export class InfoPostWebsiteEffects {

    constructor(
        private actions$: Actions,
        private reportingService: ReportingService,
        private tspService: TspService,
        private cbhService: CoastalBendHeaderService,
        private _measurement: MeasurementsService,
    ) { }

    loadNotices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchNoticeInfo),
            switchMap((action) => {
                return this.reportingService.getInfoPostNoticeDetails(action.note).pipe(
                    map((items: InfoPostNoticeDetails) => {
                        return fetchNoticeInfoSuccess({ notices: items })
                    }),
                    catchError(error => of(fetchNoticeInfoError({ error: error })))
                )
            }),
        ),
    );

    loadNoticeTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchNoticeTypeInfo),
            switchMap(() => {
                return this.reportingService.getInfoPostNoticeTypeDetails().pipe(
                    map((items: Array<InfoPostNoticeTypes>) => {
                        return fetchNoticeTypeInfoSuccess({ noticeTypes: items })
                    }),
                    catchError(error => of(fetchNoticeTypeInfoError({ error: error })))
                )
            }),
        ),
    );

    loadPostings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPostingsList),
            mergeMap((action) => {
                return this.reportingService.getAllPostingsDetailsHandler(action.posting).pipe(
                    map((items: any) => {
                        return fetchPostingsListSuccess({ postings: items })
                    }),
                    catchError(error => of(fetchPostingsListError({ error: error })))
                )
            }),
        ),
    );

    loadNaesbList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchNaesbList),
            switchMap((action) => {
                return this.reportingService.getAllPostingsDetailsHandler(action.naesb).pipe(
                    map((items: any) => {
                        return fetchNaesbListSuccess({ naesb: items })
                    }),
                    catchError(error => of(fetchNaesbListError({ error: error })))
                )
            }),
        ),
    );

    loadInfoPostTSP$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchInfoPostTspInfo),
            switchMap(() => {
                return this.tspService.getInfoPostAllTsp(LABELS.ACTIVE)
                    .pipe(map((items: Array<ServiceProvider>) => {
                        return fetchInfoPostTspInfoSuccess({ tspServiceRequester: items })
                    }), catchError(error => of(fetchInfoPostTspInfoError({ error: error }))))
            }),
        ),
    );

    loadMenuItemComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchMenuItemComments),
            switchMap((action) => {
                return this.reportingService.getStaticInformationDetails(action.tspID, action.infoPostID)
                    .pipe(map((items: StaticInformationDetails) => {
                        return fetchMenuItemCommentsSuccess({ menuitemsComments: items })
                    }), catchError(error => of(fetchMenuItemCommentsError({ error: error }))))
            }),
        ),
    );

    loadNoticeCapacityReleaseInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchNoticeCapacityReleaseIndicesInfo),
            switchMap((action) => {
                return this.reportingService.getNoticeCapacityRelease(action.tspID, action.pageNumber, action.pageSize)
                    .pipe(map((items: NoticeCapacityReleaseIndexDetails) => {
                        return fetchNoticeCapacityReleaseIndicesInfoSuccess({ capacityRelease: items })
                    }), catchError(error => of(fetchNoticeCapacityReleaseIndicesInfoError({ error: error }))))
            }),
        ),
    );

    loadGroupByPostings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchGroupByPostingsList),
            concatMap(action =>
                this.reportingService.getAllPostingsDetailsHandler(action.groupByPosting).pipe(
                    map((groupByPostingData: any) => {
                        return fetchGroupByPostingsListSuccess({
                            tspId: action.groupByPosting.tspId,
                            groupByPostingsData: groupByPostingData
                        });
                    }),
                    catchError(error => of(fetchPostingsListError({ error })))
                )
            )
        )
    );

    loadAllNotices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAllNotices),
            concatMap(action =>
                this.reportingService.getInfoPostNoticeDetails(action.note).pipe(
                    map((items: InfoPostNoticeDetails) => {
                        return fetchAllNoticesSuccess({
                            tspId: action.note.tspId,
                            allNotices: items
                        });
                    }),
                    catchError(error => of(fetchPostingsListError({ error })))
                )
            )
        )
    );

    downloadNoticeDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadNoticeDocumentAction),
            map(action => action.noticeID),
            switchMap((noticeID: number) => {
                return of(`${this.reportingService.configuration.basePath}/infopost/notices?noticeId=${noticeID}`)
                    .pipe(
                        map(resp => {
                            window.open(resp, '_blank', 'noopener');
                            return downloadNoticeDocumentActionSuccess();
                        }),
                        catchError(error => of(downloadNoticeDocumentActionError(error)))
                    )
            })
        )
    );

    FetchReceiptGasCompositionData$ = createEffect(() =>
        this.actions$.pipe(
         ofType(fetchReceiptGasComposition),
         switchMap(() => {
             return this.cbhService.getRealTimeData().pipe(
                 map((result) => {
                     const data: RealTimeRollingAvg = result;
                     return fetchReceiptGasCompositionSuccess({data})
                 }),
                 catchError(error => of(fetchReceiptGasCompositionError({error: error})))
             )
         })
        ) 
     );
 
     FetchRollingAvgGasCompositionData$ = createEffect(() =>
         this.actions$.pipe(
          ofType(fetchRollingAvgGasComposition),
          switchMap(() => {
              return this.cbhService.getCbhRollingAverage().pipe(
                  map((result) => {
                      const data: RealTimeRollingAvg = result;
                      return fetchRollingAvgGasCompositionSuccess({data})
                  }),
                  catchError(error => of(fetchRollingAvgGasCompositionError({error: error})))
              )
          })
         ) 
      );
 
     FetchStrattonRidgeGasCompositionData$ = createEffect(() =>
         this.actions$.pipe(
          ofType(fetchStrattonRidgeGasComposition),
          switchMap(() => {
              return this.cbhService.getStrattonRidgeDetails().pipe(
                  map((result) => {
                      const data: BlendingModel = result;
                      return fetchStrattonRidgeGasCompositionSuccess({data})
                  }),
                  catchError(error => of(fetchStrattonRidgeGasCompositionError({error: error})))
              )
          })
         ) 
    );

}