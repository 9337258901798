// tslint:disable
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePipeModule } from 'shared/pipes/date.pipe';

@NgModule({
  imports: [AngularCommonModule, DatePipeModule],
  exports: [AngularCommonModule, DatePipeModule],
})
export class CommonModule {}
