import {  Notice, NoticeDetails, NoticeFilterRequest, Noticeslist, ReportingService } from '@gms/reporting-api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CreateNoticeAction, CreateNoticeFailureAction, CreateNoticeSuccessAction, DeleteNoticeAction, DeleteNoticeActionFailure, DeleteNoticeActionSuccess, DownloadNoticeDocumentAction, DownloadNoticeDocumentActionFailure, DownloadNoticeDocumentActionSuccess, ENoticeActions,
        FetchNoticeLookupAction,
        FetchNoticeLookupFailure,
        FetchNoticeLookupSuccess,
        GetNoticeDetailsById,
        GetNoticeDetailsByIdFailure,
        GetNoticeDetailsByIdSuccess,
        LoadNoticeListFailureAction,
        LoadNoticeListRequestAction,
        LoadNoticeListSuccessAction } from 'app/store/notices/notice.actions';
import { NoticeParamsModel } from 'app/store/notices/notice-response-model';


@Injectable({
  providedIn: 'root',
})
export class NoticeEffects {
  constructor(
    private actions$: Actions,
    private reportingService: ReportingService,

  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNoticeListRequestAction>(ENoticeActions.FETCH_NOTICES_LIST),
    map(action => action.payload),
    switchMap(action => {
      const params: NoticeParamsModel = action;
      return this.reportingService.noticesDetails(
        params.tspId,
        params.filterSupersededTerminatedNotices,
        params.noticeEffectiveDate,
        params.noticeCategory,
        params.noticeTypeDesc,
        params.noticeId?.toString(),
        params.noticeEndDate,
        params.pageNumber,
        params.pageSize,
        params.sortBy[0].field,
        (params.sortBy[0].dir === 'desc') ? true : false,
      ).pipe(
        map((items: NoticeDetails) => {
          return new LoadNoticeListSuccessAction(items)
        }),
        catchError(error => of(new LoadNoticeListFailureAction({ error: error })))
      )
    })
  ))

  fetchNoticeLookupEffect$ = createEffect(() => this.actions$.pipe(
    ofType<FetchNoticeLookupAction>(ENoticeActions.FETCH_NOTICE_LOOKUP),
    map(action => action.mode),
    switchMap(action => {
      return this.reportingService.getNoticeDetails(action).pipe(
        map((items: Notice[]) => {
          return new FetchNoticeLookupSuccess(items)
        }),
        catchError(error => of(new FetchNoticeLookupFailure({ error: error })))
      )
    })
  ))
  deleteRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteNoticeAction>(ENoticeActions.DELETE_NOTICE),
    switchMap(action => {
      return this.reportingService.deleteNotice(action.noticeId).pipe(
        map(() => {
          return new DeleteNoticeActionSuccess(true)
        }),
        catchError(error => of(new DeleteNoticeActionFailure({ error: error })))
      )
    })
  ))

  createRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<CreateNoticeAction>(ENoticeActions.CREATE_NEW_NOTICE),
    switchMap(action => {
      return this.reportingService.postNoticesDetails(action.payload).pipe(
        map((item: Noticeslist) => {
          return new CreateNoticeSuccessAction(item)
        }),
        catchError(error => of(new CreateNoticeFailureAction({ error: error })))
      )
    })
  ))

  getNoticeDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType<GetNoticeDetailsById>(ENoticeActions.FETCH_NOTICE_BY_ID),
    map(action => action),
    switchMap(action => {
      return this.reportingService.viewNoticeDetails(action.noticeId).pipe(
        map((item: Noticeslist) => {
          return new GetNoticeDetailsByIdSuccess(item)
        }),
        catchError(error => of(new GetNoticeDetailsByIdFailure({ error: error })))
      )
    })
  ))

  downloadNoticeDocument$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadNoticeDocumentAction>(ENoticeActions.DOWNLOAD_NOTICE_DOCUMENT),
      map(action => action.noticeTrackedID),
      switchMap((noticeTrackedID: number) => {

        return of(`${this.reportingService.configuration.basePath}/infopost/notices?noticeId=${noticeTrackedID}`)
          .pipe(
            map(resp => {
              window.open(resp, '_blank', 'noopener');
              return new DownloadNoticeDocumentActionSuccess();
            }),
            catchError(error => of(new DownloadNoticeDocumentActionFailure(error)))
          )
      })
    )
  );

}
