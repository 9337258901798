import { NgModule } from '@angular/core';
import { CommonModule } from 'app/common.module';
import { LogoSpinnerComponent } from 'shared/components/loading-spinner/logo-spinner/logo-spinner.component';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
  imports: [CommonModule],
  exports: [LoadingSpinnerComponent],
  declarations: [LoadingSpinnerComponent, LogoSpinnerComponent],
  providers: [],
})
export class LoadingSpinnerModule {}
