import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** API module imports */
import { ApiConfigFactory } from 'app/apis/contracts/config-factory';

import { ApiModule as AccountingAPIModule } from '@gms/accounting-api';
import { ApiModule as AccountingEngineAPIModule } from '@gms/accountingengine-api';
import { ApiModule as ActivityAPIModule } from '@gms/activity-api';
import { ApiModule as AllocationsAPIModule } from '@gms/allocation-api';
import { ApiModule as AuctionAPIModule } from '@gms/auction-api';
import { ApiModule as BillingAPIModule } from '@gms/billing-api';
import { ApiModule as CapacityReleaseAPIModule } from '@gms/capacityrelease-api';
import { ApiModule as ClosingAPIModule } from '@gms/closing-api';
import { ApiModule as CommonApiModule } from '@gms/common-api';
import { ApiModule as CommunicationApiModule } from '@gms/communication-api';
import { ApiModule as ContractApiModule } from '@gms/contract-api';
import { ApiModule as EntityManagementAPIModule } from '@gms/entity-api';
import { ApiModule as ImbalanceApiModule } from '@gms/imbalance-api';
import { ApiModule as InvoicingApiModule } from '@gms/invoicing-api';
import { ApiModule as JobScheduleApiModule } from '@gms/jobschedule-api';
import { ApiModule as LocationApiModule } from '@gms/location-api';
import { ApiModule as MeasurementAPIModule } from '@gms/measurement-api';
import { ApiModule as NominationApiModule } from '@gms/nomination-api';
import { ApiModule as PipelineApiModule } from '@gms/pipeline-api';
import { ApiModule as RateContractApiModule } from '@gms/rate-api';
import { ApiModule as RateScheduleAPIModule } from '@gms/rateschedulev2-api';
import { ApiModule as ReportingApiModule } from '@gms/reporting-api';
import { ApiModule as RepResponsibilityApiModule } from '@gms/represponsibility-api';
import { ApiModule as SchedulingApiModule } from '@gms/scheduling-api';
import { ApiModule as ServiceAPIModule } from '@gms/security-api';
import { ApiModule as ServiceRequestApiModule } from '@gms/servicerequest-api';
import { ApiModule as SystemPlanningApiModule } from '@gms/systemplanning-api';
import { ApiModule as TspsApiModule } from '@gms/tsp-api';
import { ApiModule as UserManagementAPIModule } from '@gms/user-api';
import { ApiModule as WorkflowAPIModule } from '@gms/workflow-api';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { environment } from 'environments/environment';
import { WebsocketService } from 'shared/services/websocket.service';

/** view module imports */
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { appReducers, metaReducers } from 'app/store/app/app.reducers';
import { EffectsModule } from 'app/store/app/effects.module';
import { ErrorInterceptor, TokenInterceptor } from 'app/store/auth/auth.interceptor';
import { TelerikReportServerService } from 'app/store/reports/telerik-report-server.service';
import { PipelineSystemService } from '../shared/services/pipeline-system.service';
import { CwrErrorHandler } from './cwr-error-handler';
import { InfopostModule } from './modules/infopost/infopost.module';
import { LoadingSpinnerModule } from 'shared/components/loading-spinner/loading-spinner.module';

const ANGULAR_MODULES = [BrowserAnimationsModule, BrowserModule, HttpClientModule];

const API_MODULES = [
  AccountingAPIModule.forRoot(ApiConfigFactory.accounting),
  AccountingEngineAPIModule.forRoot(ApiConfigFactory.accountingEngine),
  ActivityAPIModule.forRoot(ApiConfigFactory.activity),
  AllocationsAPIModule.forRoot(ApiConfigFactory.allocations),
  AuctionAPIModule.forRoot(ApiConfigFactory.auctions),
  BillingAPIModule.forRoot(ApiConfigFactory.billing),
  ClosingAPIModule.forRoot(ApiConfigFactory.closing),
  CapacityReleaseAPIModule.forRoot(ApiConfigFactory.capacityRelease),
  EntityManagementAPIModule.forRoot(ApiConfigFactory.entity),
  RateScheduleAPIModule.forRoot(ApiConfigFactory.rateSchedule2),
  UserManagementAPIModule.forRoot(ApiConfigFactory.user),
  CapacityReleaseAPIModule.forRoot(ApiConfigFactory.capacityRelease),
  ContractApiModule.forRoot(ApiConfigFactory.contract),
  CommonApiModule.forRoot(ApiConfigFactory.common),
  ServiceRequestApiModule.forRoot(ApiConfigFactory.serviceRequest),
  MeasurementAPIModule.forRoot(ApiConfigFactory.measurement),
  NominationApiModule.forRoot(ApiConfigFactory.nomination),
  SchedulingApiModule.forRoot(ApiConfigFactory.scheduling),
  TspsApiModule.forRoot(ApiConfigFactory.tsp),
  RateContractApiModule.forRoot(ApiConfigFactory.rates),
  LocationApiModule.forRoot(ApiConfigFactory.location),
  PipelineApiModule.forRoot(ApiConfigFactory.pipeline),
  WorkflowAPIModule.forRoot(ApiConfigFactory.workflow),
  CommunicationApiModule.forRoot(ApiConfigFactory.communication),
  ImbalanceApiModule.forRoot(ApiConfigFactory.imbalance),
  InvoicingApiModule.forRoot(ApiConfigFactory.invoicing),
  JobScheduleApiModule.forRoot(ApiConfigFactory.jobSchedule),
  RepResponsibilityApiModule.forRoot(ApiConfigFactory.repResponsibility),
  SystemPlanningApiModule.forRoot(ApiConfigFactory.systemPlanning),
  ReportingApiModule.forRoot(ApiConfigFactory.reporting),
  ServiceAPIModule.forRoot(ApiConfigFactory.security),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    ReactiveFormsModule,
    NotificationModule,
    AppRoutingModule,
    EffectsModule,
    LoadingSpinnerModule,
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    InfopostModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ...ANGULAR_MODULES,
    ...API_MODULES,
  ],
  providers: [
    PipelineSystemService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    WebsocketService,
    TelerikReportServerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
